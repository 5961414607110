<template>
  <TTView>
    <VRow>
      <VCol>
        <h2>Сетки без левого и верхнего меню</h2>

        <VList>
          <VListItemGroup>
            <VListItem
              v-for="(example, index) in gridExamples"
              :key="index"
              :to="example.to"
              target="_blank"
            >
              <VListItemContent>
                <VListItemTitle>{{ example.title }}</VListItemTitle>
              </VListItemContent>
            </VListItem>
          </VListItemGroup>
        </VList>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Grid',

  inject: ['Names'],

  data() {
    return {
      gridExamples: [
        {
          title: 'Сетка платформы без вложенного контейнера (ОСНОВНАЯ СЕТКА)',
          to: { name: this.Names.R_EXAMPLES_GRIDS_PLATFORM_GRID },
        },

        {
          title: 'Сетка платформы с вложенным контейнером',
          to: { name: this.Names.R_EXAMPLES_GRIDS_PLATFORM_GRID2 },
        },

        {
          title: 'Сетка 12 колонок',
          to: { name: this.Names.R_EXAMPLES_GRIDS_12 },
        },

        {
          title: 'Сетка 1х10х1 колонок',
          to: { name: this.Names.R_EXAMPLES_GRIDS_1X10X1 },
        },
      ],
    };
  },
};
</script>
